import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './Style/Style.css';
import 'react-toastify/dist/ReactToastify.css';
import './Style/Mediaquries.css'
import 'wowjs/css/libs/animate.css';
import 'animate.css';
import img from './Images/whatsapplogo.png'
 import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
//  import Home from './Pages/Home/Home';
import ScrollToTop from './Components/ScrollTop';

const About = React.lazy(() => import('./Pages/Aboutus/Aboutus'))
const InvoicePage = React.lazy(() => import('./Pages/UmrahPackages/Invoicepage'))
const ViewDeail = React.lazy(() => import('./Pages/ViewDetail/ViewDetail'))
const SearchTour = React.lazy(() => import('./Pages/SearchTour/SearchTour'))
const Hotels = React.lazy(() => import('./Pages/Hotels/Hotel'))
const HotelDetail = React.lazy(() => import('./Pages/Hotels/HotelDetail'))
const BookRoom = React.lazy(() => import('./Pages/BookRoom/BookRoom'))
const FlightCheckout = React.lazy(() => import('./Pages/Flight/FlightCheckout'))
const BookPackage = React.lazy(() => import('./Pages/UmrahPackages/BookPackage'))
const Confirmation = React.lazy(() => import('./Pages/BookingConfirmation/Confirmation'))
const PackageCheckout = React.lazy(() => import('./Pages/UmrahPackages/Package_checkout'))
const PackageInvoice2 = React.lazy(() => import('./Pages/UmrahPackages/PackageInvoice2'))
const FlightListing = React.lazy(() => import('./Pages/Flight/FlightListing'))
const FlightInvoice = React.lazy(() => import('./Pages/Flight/FlightInvoice'))
const UmrahPackage2 = React.lazy(() => import('./Pages/UmrahPackages/umrahpackage2'))
const FaqPage = React.lazy(() => import('./Pages/FooterPages/faqPage'))
const PrivacyPolicy = React.lazy(() => import('./Pages/FooterPages/PrivacyPolicyPage'))
const TermsConditions = React.lazy(() => import('./Pages/FooterPages/TermsConditions'))
const ComplaintPolicy = React.lazy(() => import('./Pages/FooterPages/ComplaintPolicy'))
const Activities = React.lazy(() => import('./Pages/Activities/Activities'))
const HajjDetail = React.lazy(() => import('./Pages/Hajj/HajjDetail'))
const ActivityDetail = React.lazy(() => import('./Pages/Activities/ActivityDetail'))
const ActivityCheckout = React.lazy(() => import('./Pages/Activities/ActivityCheckout'))
const ActivityInvoice = React.lazy(() => import('./Pages/Activities/ActivityInvoice'))
const ConfirmationMessage = React.lazy(() => import('./Pages/Hajj/ConfirmationMessage'))
const TransferListing = React.lazy(() => import('./Pages/Transfer/TransferListing'))
const TransferCheckout = React.lazy(() => import('./Pages/Transfer/TransferCheckout'))
const TransferInvoice = React.lazy(() => import('./Pages/Transfer/TransferInvoice'))
const HajjPackage = React.lazy(() => import('./Pages/HajjPackages/Home'))
const Guides = React.lazy(() => import('./Pages/HajjPackages/Guides'))
const HotelInvoice = React.lazy(() => import('./Pages/BookingConfirmation/HotelInvoice'))
const SupportChanel = React.lazy(() => import('./Pages/HajjPackages/SupportChanel'))
const NotFoundPage = React.lazy(() => import('./Pages/404page'))
const Emailvarify = React.lazy(()=> import('./Pages/Emailvarify/Emailvarify'))

// import {FloatingWhatsApp} from 'react-floating-whatsapp';
// import whatsappcumenity from './Images/whatsappcuminityBtn.png'
// import telegramcum from './Images/telegramcomunityBtn.png'
const Home = React.lazy(() => import('./Pages/Home/Home'))
const Contact = React.lazy(() => import('./Pages/Contactus/Contact'))
function App() {
  return (
   <>
    {/* <div class='f_cta_icons'>
          <div
            class='chaty-channel Phone-channel'
            id='Phone-0-channel'
            data-id='Phone-0'
            data-widget='0'
            data-channel='Phone'
          >
            <a href='tel:0121 777 2522'>
              <span class='chaty-svg'>
                <svg
                  width='39'
                  height='39'
                  viewBox='0 0 39 39'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    class='color-element'
                    cx='19.4395'
                    cy='19.4395'
                    r='19.4395'
                    fill='#03E78B'
                  ></circle>
                  <path
                    d='M19.3929 14.9176C17.752 14.7684 16.2602 14.3209 14.7684 13.7242C14.0226 13.4259 13.1275 13.7242 12.8292 14.4701L11.7849 16.2602C8.65222 14.6193 6.11623 11.9341 4.47529 8.95057L6.41458 7.90634C7.16046 7.60799 7.45881 6.71293 7.16046 5.96705C6.56375 4.47529 6.11623 2.83435 5.96705 1.34259C5.96705 0.596704 5.22117 0 4.47529 0H0.745882C0.298353 0 5.69062e-07 0.298352 5.69062e-07 0.745881C5.69062e-07 3.72941 0.596704 6.71293 1.93929 9.3981C3.87858 13.575 7.30964 16.8569 11.3374 18.7962C14.0226 20.1388 17.0061 20.7355 19.9896 20.7355C20.4371 20.7355 20.7355 20.4371 20.7355 19.9896V16.4094C20.7355 15.5143 20.1388 14.9176 19.3929 14.9176Z'
                    transform='translate(9.07179 9.07178)'
                    fill='white'
                  ></path>
                </svg>
              </span>
            </a>
          </div>
          <div class='chaty-channel Whatsapp-channel' id='Whatsapp-0-channel'>
            <a
              href='https://wa.me/+447309803307?text=Hi, I would like to contact you from Alhijaz Tours.'
              target='_blank'
              class='chaty-tooltip pos-right'
            >
              <span class='chaty-svg'>
                <svg
                  width='39'
                  height='39'
                  viewBox='0 0 39 39'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle
                    class='color-element'
                    cx='19.4395'
                    cy='19.4395'
                    r='19.4395'
                    fill='#49E670'
                  ></circle>
                  <path
                    d='M12.9821 10.1115C12.7029 10.7767 11.5862 11.442 10.7486 11.575C10.1902 11.7081 9.35269 11.8411 6.84003 10.7767C3.48981 9.44628 1.39593 6.25317 1.25634 6.12012C1.11674 5.85403 2.13001e-06 4.39053 2.13001e-06 2.92702C2.13001e-06 1.46351 0.83755 0.665231 1.11673 0.399139C1.39592 0.133046 1.8147 1.01506e-06 2.23348 1.01506e-06C2.37307 1.01506e-06 2.51267 1.01506e-06 2.65226 1.01506e-06C2.93144 1.01506e-06 3.21063 -2.02219e-06 3.35022 0.532183C3.62941 1.19741 4.32736 2.66092 4.32736 2.79397C4.46696 2.92702 4.46696 3.19311 4.32736 3.32616C4.18777 3.59225 4.18777 3.59224 3.90858 3.85834C3.76899 3.99138 3.6294 4.12443 3.48981 4.39052C3.35022 4.52357 3.21063 4.78966 3.35022 5.05576C3.48981 5.32185 4.18777 6.38622 5.16491 7.18449C6.42125 8.24886 7.39839 8.51496 7.81717 8.78105C8.09636 8.91409 8.37554 8.9141 8.65472 8.648C8.93391 8.38191 9.21309 7.98277 9.49228 7.58363C9.77146 7.31754 10.0507 7.1845 10.3298 7.31754C10.609 7.45059 12.2841 8.11582 12.5633 8.38191C12.8425 8.51496 13.1217 8.648 13.1217 8.78105C13.1217 8.78105 13.1217 9.44628 12.9821 10.1115Z'
                    transform='translate(12.9597 12.9597)'
                    fill='#FAFAFA'
                  ></path>
                  <path
                    d='M0.196998 23.295L0.131434 23.4862L0.323216 23.4223L5.52771 21.6875C7.4273 22.8471 9.47325 23.4274 11.6637 23.4274C18.134 23.4274 23.4274 18.134 23.4274 11.6637C23.4274 5.19344 18.134 -0.1 11.6637 -0.1C5.19344 -0.1 -0.1 5.19344 -0.1 11.6637C-0.1 13.9996 0.624492 16.3352 1.93021 18.2398L0.196998 23.295ZM5.87658 19.8847L5.84025 19.8665L5.80154 19.8788L2.78138 20.8398L3.73978 17.9646L3.75932 17.906L3.71562 17.8623L3.43104 17.5777C2.27704 15.8437 1.55796 13.8245 1.55796 11.6637C1.55796 6.03288 6.03288 1.55796 11.6637 1.55796C17.2945 1.55796 21.7695 6.03288 21.7695 11.6637C21.7695 17.2945 17.2945 21.7695 11.6637 21.7695C9.64222 21.7695 7.76778 21.1921 6.18227 20.039L6.17557 20.0342L6.16817 20.0305L5.87658 19.8847Z'
                    transform='translate(7.7758 7.77582)'
                    fill='white'
                    stroke='white'
                    stroke-width='0.2'
                  ></path>
                </svg>
              </span>
            </a>
          </div>
          <div class='chaty-channel Whatsapp-channel' id='Whatsapp-0-channel'>
            <a
              href='https://t.me/+tF5MQ2KCCCU1Y2Zk'
              target='_blank'
              class='chaty-tooltip pos-right'
            >
              <span class='chaty-svg'>
                <svg
                  width='39'
                  height='39'
                  viewBox='0 0 256 256'
                  version='1.1'
                  preserveAspectRatio='xMidYMid'
                  fill='#000000'
                >
                  <g>
                    <path
                      d='M128,0 C57.307,0 0,57.307 0,128 L0,128 C0,198.693 57.307,256 128,256 L128,256 C198.693,256 256,198.693 256,128 L256,128 C256,57.307 198.693,0 128,0 L128,0 Z'
                      fill='#40B3E0'
                    />
                    <path
                      d='M190.2826,73.6308 L167.4206,188.8978 C167.4206,188.8978 164.2236,196.8918 155.4306,193.0548 L102.6726,152.6068 L83.4886,143.3348 L51.1946,132.4628 C51.1946,132.4628 46.2386,130.7048 45.7586,126.8678 C45.2796,123.0308 51.3546,120.9528 51.3546,120.9528 L179.7306,70.5928 C179.7306,70.5928 190.2826,65.9568 190.2826,73.6308'
                      fill='#FFFFFF'
                    />
                    <path
                      d='M98.6178,187.6035 C98.6178,187.6035 97.0778,187.4595 95.1588,181.3835 C93.2408,175.3085 83.4888,143.3345 83.4888,143.3345 L161.0258,94.0945 C161.0258,94.0945 165.5028,91.3765 165.3428,94.0945 C165.3428,94.0945 166.1418,94.5735 163.7438,96.8115 C161.3458,99.0505 102.8328,151.6475 102.8328,151.6475'
                      fill='#D2E5F1'
                    />
                    <path
                      d='M122.9015,168.1154 L102.0335,187.1414 C102.0335,187.1414 100.4025,188.3794 98.6175,187.6034 L102.6135,152.2624'
                      fill='#B5CFE4'
                    />
                  </g>
                </svg>
              </span>
            </a>
          </div>
          <div class='join'>
            <a
              href='https://chat.whatsapp.com/JwU1rZbEuPb1aiKdkx9I2c '
              target='_blank'
            >
              <img
                src={whatsappcumenity}
                style={{ width: '45px' }}
              />
            </a>
          </div>
          
          <div class='myDIV'>
            <a href='https://forms.gle/V5zacXcgib2UgKTj9 ' target='_blank'>
              <img
                src={telegramcum}
                style={{ width: '45px', marginTop: '7px' }}
              />
            </a>
          </div>
        </div> */}
   {/* <FloatingWhatsApp
        phoneNumber="+44 7309 803307" // Your WhatsApp number
        accountName="Alhijaz Tours"
        avatar={img}
        statusMessage="Online"
        chatMessage="Hello! How can I help you today?"
        placeholder="Type a message..."
      /> */}
   <Router>
   <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/about-us" element={<About />} />
      {/* <Route path="/umrah-packages" element={<Umrahpackages />} /> */}
      <Route path="/umrah-package/:id" element={<ViewDeail />} />
      <Route path="/search-tour" element={<SearchTour />} />
      <Route path="/hotels" element={<Hotels />} />
      <Route path="/hotel-detail/:id" element={<HotelDetail />} />
      <Route path="/hotel-checkout" element={<BookRoom />} />
      <Route path="/book-package" element={<BookPackage />} />
      <Route path="/package-invoice/:id" element={<InvoicePage />} />
      <Route path="/package-checkout" element={<PackageCheckout />} />
      <Route path="/umrah-packages" element={<UmrahPackage2 />} />
      <Route path="/invoice-package/:id/:id/:id" element={<PackageInvoice2 />} />
      <Route path="/Flight-search" element={<FlightListing />} />
      <Route path="/Flight-checkout" element={<FlightCheckout />} />
      <Route path="/Flight-invoice/:id" element={<FlightInvoice />} />
      <Route path="/faqs" element={<FaqPage />} />
      <Route path="/complaint-policy" element={<ComplaintPolicy/>} />
      <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
      <Route path="/terms-and-conditions" element={<TermsConditions/>} />
      <Route path="/hotel-booking-invoice/:id" element={<Confirmation />} />
      <Route path="/hotel-invoice/:id" element={<HotelInvoice />} />
      <Route path="/activities" element={<Activities />} />
      <Route path="/activity-details/:id" element={<ActivityDetail />} />
      <Route path="/activity-checkout" element={<ActivityCheckout />} />
      <Route path="/activity-invoice/:id" element={<ActivityInvoice />} />
      <Route path="/hajj" element={<HajjDetail />} />
      <Route path="/hajj-confirmation" element={<ConfirmationMessage />} />
      <Route path="/transfer-search" element={<TransferListing />} />
      <Route path="/transfer-checkout" element={<TransferCheckout />} />
      <Route path="/transfer-invoice/:id" element={<TransferInvoice />} />
      <Route path="/hajj-packages" element={<HajjPackage />} />
      <Route path="/guides" element={<Guides />} />
      <Route path="/support-channel" element={<SupportChanel />} />
      <Route path="*" element={<NotFoundPage />} />
      <Route path='/booking' element={<Emailvarify />} />
      
    </Routes>
   
    </Router>
   
   </>
  );
}

export default App;
